/**
 * @description
 *
 * This is a little library designed to handle personnummer. The idea is to make it reusable.  Pass a string and it returns a structure with some interesting
 * data or throws an exception if not a valid personnummer.
 *
 * <i>The code for personnummer check is adapted from https://github.com/personnummer/js when copied
 * March 21st 2019 released under MIT licence</i>.
 *
 * @license Apache-2.0
 * @author Daniel Bergdahl <rexsuecia@gmail.com>
 * @copyright 2022 LINAF AB
 *
 */
/**
 * Calculates the expected luhn.
 * @param personNummer - The personnummer without century and no luhn, id est 9
 * @return {number} - The Luhn check digit.
 */
var getLuhn = function (personNummer) {
    var v = 0;
    var sum = 0;
    for (var i = 0, l = personNummer.length; i < l; i++) {
        v = Number.parseInt(personNummer[i], 10);
        v *= 2 - (i % 2);
        if (v > 9) {
            v -= 9;
        }
        sum += v;
    }
    return (Math.ceil(sum / 10) * 10 - sum);
};
/**
 * Generates a fake personnummer.
 *
 * @return {string} - A valid personnummer
 */
export var generate = function () {
    var centuries = ['19', '19', '19', '20']; // 3 times as likely to get an older person.
    var rand = Math.random();
    var century = centuries[Math.floor(rand * centuries.length)];
    rand = Math.random();
    var year = Math.floor(rand * (century === '19' ? 100 : 12));
    if (year < 10) {
        year = '0' + year;
    }
    rand = Math.random();
    var month = Math.floor(rand * 12) + 1;
    if (month < 10) {
        month = '0' + month;
    }
    rand = Math.random();
    var day = Math.floor(rand * 28) + 1;
    if (day < 10) {
        day = '0' + day;
    }
    year = year + '';
    month = month + '';
    day = day + '';
    var separator = '-';
    var age = calculateAge(Number.parseInt(century + year), Number.parseInt(month), Number.parseInt(day));
    if (age > 100) {
        separator = '+';
    }
    var num = Math.floor(Math.random() * 100) + 100;
    var luhn = getLuhn(year + month + day + num + '');
    return century + year + month + day + separator + num + luhn;
};
/**
 * Luhn calculus on a personnummer. Will work with organisationsnummer as well.
 * @param personNummer - The personnummer without century and no separator, e.g. 7309193592
 * @return {undefined} - Returns empty if ok or throws exception if not.
 */
var checkLuhn = function (personNummer) {
    var check = Number.parseInt(personNummer[9]);
    var luhn = getLuhn(personNummer.substring(0, 9));
    if (luhn !== check) {
        throw new Error("Luhn check failed: ".concat(luhn));
    }
};
/**
 * Test if the supplied numbers actually constitutes a date. If not then
 * it is not a personnummer after all.
 *
 * @param year - As in 1973 or 2011, with century
 * @param month - Should be the natural month as in 1 for January and 9 for September
 * @param day - Day of month, 1 - 31
 *
 * @return {null}
 */
var checkDate = function (year, month, day) {
    month -= 1;
    var date = new Date(year, month, day);
    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
        throw new Error('Not a valid date');
    }
};
/**
 * Calculates the age based on the input year, month a day. Increments
 * year on the day of birth.
 *
 * @param {number} year - The year *with* century e.g 2012 or 1973
 * @param {number} month - Month, where 1 = January , 12 = December
 * @param {number} day - The day of month 1 - 31
 * @return {number} - The age in full years.
 */
var calculateAge = function (year, month, day) {
    var today = new Date();
    var tYear = today.getFullYear();
    var years = tYear - year;
    if (today.getMonth() + 1 >= month && today.getDate() >= day) {
        years++;
    }
    return years;
};
/**
 * Validate a personnummer and return an object with nice representations of the personnummer.
 *
 * @param {string} personNummer - The personnummer to check
 * @return - Returns the personnummer with nice formatting for use in various services.
 */
export var verify = function (personNummer) {
    var reg = /^(\d{2})?(\d{2})(\d{2})(\d{2})([-|+]?)?(\d{3})(\d?)$/;
    var match = reg.exec(personNummer.trim());
    if (!match) {
        throw new Error('Not correctly formatted: ' + personNummer);
    }
    var century = match[1];
    var year = match[2];
    var month = match[3];
    var day = match[4];
    var separator = match[5];
    var num = match[6];
    var check = match[7];
    if (separator === undefined) {
        separator = '-';
    }
    if (separator === '+') {
        century = '19';
    }
    if (!century) {
        var today = new Date();
        var tYear = today.getFullYear().toString().substring(2, 4);
        century = Number.parseInt(year) < Number.parseInt(tYear) ? '20' : '19';
    }
    // Check if day is samordningsnummer if so use actual date to
    // test for dates where day matters.
    var testDay = Number.parseInt(day);
    if (testDay > 60) {
        testDay = testDay - 60;
    }
    checkLuhn(year + month + day + num + check);
    checkDate(Number.parseInt(century + year), Number.parseInt(month), testDay);
    return {
        input: personNummer,
        10: year + month + day + num + check,
        11: year + month + day + separator + num + check,
        12: century + year + month + day + num + check,
        13: century + year + month + day + separator + num + check,
        age: calculateAge(Number.parseInt(century + year), Number.parseInt(month), testDay),
        birthDay: month + '/' + testDay
    };
};
